<template>
  <div>
    <div class="alert-fixed">
      <div v-for="(message, index) in messages" :key="index">
        <div class="alert alert-dismissable fade in" v-bind:class="[message.classes]">
          <a class="close" v-on:click="splice(message)">&times;</a>
          {{message.text}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ivbus from "../utils/ivbus.js";
export default {
  data: function() {
    return {
      messages: [],
      nextId: 1
    };
  },
  mounted: function() {
    var that = this;
    ivbus.takeSemaphore("iv-alerts", true).then(function() {
      ivbus.onQ("ivAlertShow", that.push);
      ivbus.onQ("ivAlertHide", that.splice);
    });
  },
  beforeDestroy: function() {
    ivbus.releaseSemaphore("iv-alerts");
  },
  methods: {
    getNextId: function() {
      this.nextId++;
      return this.nextId;
    },
    push: function(message) {
      var that = this;
      message.id = this.getNextId();
      that.messages.push(message);
      if (message.timeout) {
        setTimeout(that.splice, message.timeout, message);
      }
      return message;
    },
    splice: function(message) {
      if (!message) return;
      var that = this;
      var foundIndex = null;
      for (var i = 0; i < that.messages.length; i++) {
        if (that.messages[i].id == message.id) {
          foundIndex = i;
          break;
        }
      }
      if (foundIndex != null) {
        that.messages.splice(foundIndex, 1);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.alert-fixed {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 30%;
  z-index: 9999;
  border-radius: 0px;
}
</style>

